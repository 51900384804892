@import 'codebase';

@import 'partials/global';
@import 'partials/classes';

@import '~flickity';

*
{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html
{
    height: 100%;
}

body
{
    min-height: 100%;
    background-color: theme-color('light_grey');
    font-family: 'Myriad W01 Regular', sans-serif;
    font-size: 1.15rem;
    overflow-x: hidden; // fix for select2-dropdowns
}

main
{
    margin-top: 50px;
    margin-bottom: 50px;
}

header
{

}

.header-main
{
    position: relative;
    padding: 20px 0;

    .logo
    {
        height: 120px;
    }

    .logo-label
    {
        h1, h2
        {
            color: white;
        }

        h2
        {
            font-weight: normal;
        }
    }
}

.header-nav, .footer-nav
{
    li
    {
        list-style: none;

        a
        {
            display: block;
            padding: 10px 25px;
            transition: 0.5s;

            &.active, &:hover
            {
                background-color: rgba(black, 0.08);
            }
        }
    }
}

.header-nav
{
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, .15);

    li
    {

        a
        {
            color: theme-color('secondary');
        }
    }
}

.events-slider
{
    max-height: 104px;
    overflow: hidden;

    .event-slide
    {
        color: white;
        width: 100%;

        .slider-title
        {
            font-size: 3.5rem;
        }
    }

    .flickity-button
    {
        background: transparent;
        color: rgba(white, 0.5);
        height: 100%;
        border-radius: 0;
        pointer-events: initial;

        &.previous
        {
            left: 0;

            svg
            {
                left: 0;
                right: auto;
            }
        }

        &.next
        {
            right: 0;

            svg
            {
                right: 0;
                left: auto;
            }
        }
    }
}

.footer-nav
{
    li
    {

        a
        {
            color: white;
        }
    }
}

.video-link
{
    border: 1px solid rgba(black, 0.2);
    background: linear-gradient(to right, white, theme-color('light_grey'));

    div
    {
        color: theme-color('secondary');
    }
}

.sponsors
{
    img
    {
        max-width: 100%;
    }
}

.language-switcher
{
    list-style: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    margin: auto;

    li
    {
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: white;

        a
        {
            padding: 8px;
            color: theme-color('primary');
            transition: 0.5s;
            width: 40px;
            height: 40px;

            &:hover
            {
                background-color: rgba(theme-color('secondary'), 0.2);
            }

            &.active
            {
                background-color: theme-color('secondary');
                color: white;
            }
        }
    }
}

h1
{
    margin-bottom: 24px;
}

h2
{
    margin-top: 36px;
}
