//
// Position
// --------------------------------------------------

.pos-t-5 { top: 5% !important; }
.pos-t-10 { top: 10% !important; }
.pos-t-20 { top: 20% !important; }
.pos-t-25 { top: 25% !important; }
.pos-t-30 { top: 30% !important; }
.pos-t-40 { top: 40% !important; }
.pos-t-50 { top: 50% !important; }
.pos-t-60 { top: 60% !important; }
.pos-t-70 { top: 70% !important; }
.pos-t-75 { top: 75% !important; }
.pos-t-80 { top: 80% !important; }
.pos-t-90 { top: 90% !important; }
.pos-t-100 { top: 100% !important; }

.pos-l-5 { left: 5% !important; }
.pos-l-10 { left: 10% !important; }
.pos-l-20 { left: 20% !important; }
.pos-l-25 { left: 25% !important; }
.pos-l-30 { left: 30% !important; }
.pos-l-40 { left: 40% !important; }
.pos-l-50 { left: 50% !important; }
.pos-l-60 { left: 60% !important; }
.pos-l-70 { left: 70% !important; }
.pos-l-75 { left: 75% !important; }
.pos-l-80 { left: 80% !important; }
.pos-l-90 { left: 90% !important; }
.pos-l-100 { left: 100% !important; }