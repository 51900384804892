$spacer: 10px;

$grid-gutter-width: 2 * $spacer;

$spacers: ();

@for $i from 1 through 10
{
    $spacers: map-merge($spacers, ($i : $i * $spacer));
}


$border-radius: 0;
$input-border-radius: 0;
$input-border-radius-sm: 0;

$theme-colors: (
    primary: #E2001A,
    secondary: #666666,
    tertiary: #F0F0F0,

    'light_grey': #FBFBFB,
);

$font-family-base: "Source Sans Pro", sans-serif;

$font-size-base: 1.1rem;

$h-decrease: .15rem;

$h1-font-size: 2.2rem;
$h2-font-size: $h1-font-size - $h-decrease * 5;
$h3-font-size: $h2-font-size - $h-decrease;
$h4-font-size: $h3-font-size - $h-decrease;
$h5-font-size: $h4-font-size - $h-decrease;
$h6-font-size: $h5-font-size - $h-decrease;

$headings-font-weight: 400;

$headings-margin-bottom: $spacer;

$headings-color: map-get($theme-colors, primary);

$line-height-base: 1.5;

$paragraph-margin-bottom: $spacer;

$link-hover-decoration: none;

$border-radius: 0;
$input-border-radius: 0;
$input-border-radius-sm: 0;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$fa-font-path: "../../fonts/fontawesome4";