.wrap
{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
}

.venue
{
    background-color: theme-color('tertiary');
    margin-bottom: 40px;
    border: 1px solid rgba(theme-color('secondary'), 0.25);
    overflow: hidden;

    iframe
    {
        width: 100% !important;
        height: 300px !important;
        border: 0 !important;
    }

    .nav-tabs-header
    {
        .venue-location
        {
            font-size: 3rem;
            color: red;
            text-transform: uppercase;
            hyphens: auto;
            word-wrap: break-word;
        }

        .venue-description
        {
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 25px;

            .badge.hours
            {
                text-transform: none;
            }
        }
    }

    .nav-tabs-themed
    {
        .nav-item
        {
            .nav-link
            {
                font-size: 1.4rem;
                font-weight: normal;

                &, &:hover
                {
                    color: white;
                    background: map-get($theme-colors, secondary);
                }

                &.active
                {
                    color: white;
                    background: map-get($theme-colors, primary);
                }
            }

            &:not(:last-child)
            {
                border-right: 2px solid theme-color('tertiary');
            }
        }
    }

    .tab-content
    {
        .program
        {
            .row
            {
                border-bottom: 1px solid rgba(black, 0.1);

                &:last-child
                {
                    border-bottom: 0 !important;
                    padding-bottom: 0 !important;
                }
            }
        }
    }
}

.venue.refresher
{
    h3
    {
        color: theme-color('secondary') !important;
    }

    button[type="submit"]
    {
        background-color: black;
        transition: 0.3s;
        border-color: black;

        &:hover
        {
            background-color: theme-color('secondary');
        }
    }

    iframe
    {

    }

    .nav-tabs-header
    {
        .venue-location
        {
            color: theme-color('secondary');
        }

        .venue-description
        {

            .badge.hours
            {

            }
        }
    }

    .nav-tabs-themed
    {
        .nav-item
        {
            .nav-link
            {

                h2
                {

                }

                &, &:hover
                {

                }

                &.active
                {
                    background-color: black;
                }
            }

            &:not(:last-child)
            {

            }
        }
    }
}

.monospace
{
    font-family: "Courier Prime", $font-family-base;
    font-size: $font-size-base * 1.15;
}

.select2-container
{
    width: 100% !important;

    .select2-selection
    {
        height: 40px !important;

        > span
        {
            height: 100% !important;
            line-height: 40px !important;
        }
    }
}

.font-size-0
{
    font-size: 0px;
}

.video-link
{
    position: relative;

    .video-icon
    {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
    }
}

.form-title
{
    font-size: 1.5rem;
}

.font-weight-bold, .btn
{
    font-family: 'Myriad W01 SmBd', sans-serif;
    font-weight: 400 !important;
}
