h1, h2, h3, h4, h5, h6
{
    color: theme-color('secondary');
    font-weight: 400;
    font-family: 'Myriad W01 SmBd', sans-serif;
}

input.form-control
{
    &::placeholder
    {
        color: rgba(theme-color('secondary'), 0.5);
    }
}

button
{
    border: 0;
}
