//
// Forms
// --------------------------------------------------

@mixin form-validation-state($state, $color, $icon) {
    .#{$state}-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        @include font-size($form-feedback-font-size);
        color: $color;
    }
    
    .#{$state}-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%; // Contain to parent when possible
        padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
        margin-top: .1rem;
        @include font-size($form-feedback-tooltip-font-size);
        line-height: $form-feedback-tooltip-line-height;
        color: color-yiq($color);
        background-color: rgba($color, $form-feedback-tooltip-opacity);
        @include border-radius($form-feedback-tooltip-border-radius);
    }

    .form-control {
        .was-validated &:#{$state},
        &.is-#{$state} {
            border-color: $color;

            @if $enable-validation-icons {
                padding-right: $input-height-inner;
                background-image: $icon;
                background-repeat: no-repeat;
                background-position: center right $input-height-inner-quarter;
                background-size: $input-height-inner-half $input-height-inner-half;
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }

    textarea.form-control {
        .was-validated &:#{$state},
        &.is-#{$state} {
            @if $enable-validation-icons {
                padding-right: $input-height-inner;
                background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
            }
        }
    }

    .custom-select {
        .was-validated &:#{$state},
        &.is-#{$state} {
            border-color: $color;
        
            @if $enable-validation-icons {
                padding-right: $custom-select-feedback-icon-padding-right;
                background: $custom-select-background, $icon $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
            }
        
            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }

    .form-control-file {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }
    
    .form-check-input {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .form-check-label {
                color: $color;
            }
        
            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }

    // Custom radios and checks
    .custom-control-input {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .custom-control-label {
                color: $color;

                &::before {
                    background-color: lighten($color, 25%);
                }
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }

            &:checked {
                ~ .custom-control-label::before {
                    @include gradient-bg(lighten($color, 10%));
                }
            }
        }
    }

    // Custom file
    .custom-file-input {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .custom-file-label {
                border-color: $color;
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }

            &:focus {
                ~ .custom-file-label {
                    border-color: $color;
                }
            }
        }
    }

    // Custom
    .is-#{$state} {
        .form-control {
            border-color: $color;
        }

        .form-check-input ~ .form-check-label {
            color: $color;
        }

        .custom-control-input ~ .custom-control-label {
            color: $color;

            &::before {
                background-color: rgba($color, .25);
            }
        }

        .#{$state}-feedback,
        .#{$state}-tooltip {
            display: block;
        }
    }
}

@mixin form-control-material-focus-variant($color) {
    &:focus {
        box-shadow: 0 2px $color;

        + label {
            color: $color;
        }

        ~ .input-group-append {
            color: $color;
            box-shadow: 0 2px $color;
        }
    }
}

@mixin form-validation-state-material($state, $color) {
    .is-#{$state} .form-material {
        > .form-control {
            box-shadow: 0 1px 0 $color;

            ~ .input-group-append {
                color: $color;
                box-shadow: 0 1px 0 $color;
            }

            &:focus {
                box-shadow: 0 2px $color;

                + label {
                    color: $color;
                }

                ~ .input-group-append {
                    color: $color;
                    box-shadow: 0 2px $color;
                }
            }
        }

        ~ .invalid-feedback,
        ~ .invalid-tooltip {
            display: block;
        }

        label {
            color: $color;
        }
    }
}
